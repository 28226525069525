import queryHotelOverproofStatement from "@/lib/data-service/haolv-default/consumer_t_od_hotel_statement_queryHotelOverproofStatement";
import Pagination from "@/component/pagination/index.vue";
export default {
  data() {
    return {
      loading: false,
      orderDate: null,
      list: [],
      form: {
        currentPage: 1,
        pageSize: 10,
        startDate: "",
        endDate: "",
        orderNo: '',
        overproofType: "",
      },
      overproofTypeList: [
        { label: "全部", value: "" },
        { label: "价格超标", value: "1" },
      ],
      pager: {
        total: 0,
        pageSizes: [10, 15, 20, 25, 30],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  components: {
    Pagination,
  },
  activated() {
    this.req_list();
  },
  methods: {
    req_list(type) {
      if (this.loading) return;
      this.loading = true;
      if (type) {
        this.list = [];
        this.pager.total = 0;
      }
      queryHotelOverproofStatement(this.form)
        .then((res) => {
          this.list = res.pageResult.pageData;
          this.pager.total = res.pageResult.totalCount;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    changeOrderDate(val) {
      const form = this.form;
      if (val) {
        form.startDate = val[0];
        form.endDate = val[1];
      } else {
        form.startDate = "";
        form.endDate = "";
      }
    },
    search() {
      this.form.currentPage = 1;
      this.req_list("reset");
    },
    change_currentPage(currentPage) {
      this.form.currentPage = currentPage;
      this.req_list();
    },
    change_pageSize(pageSize) {
      this.form.pageSize = pageSize;
      this.change_currentPage(1);
    },
  },
  filters: {
    format_overproofType (val) {
      switch (val) {
        case 1:
          return '价格超标';
          break;
      }
    }
  },
};
