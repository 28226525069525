// consumer_excessive_application 企业待/已支付单 (超标列表用)
const __request = require(`./__request/__request_contentType_json`);
// 统计酒店超标订单列表
// http://192.168.0.16:8765/doc.html#/haolv-consumer/t-od-hotel-statement-controller/queryHotelOverproofStatementUsingPOST
const consumer_t_od_hotel_statement_queryHotelOverproofStatement = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-statement/queryHotelOverproofStatement',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_hotel_statement_queryHotelOverproofStatement;